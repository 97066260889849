/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

function SEO({ description, lang, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "charSet",
          content: "utf-8",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "MyWash Sp. z o.o. Sp.k.",
          description: "MyWash to producent myjni bezdotykowych.",
          logo: "https://mywash.pl/assets/img/logo.svg",
          image: "https://mywash.pl/assets/img/logo.svg",
          url: "https://mywash.pl",
          taxID: "966-212-0995",
          telephone: ["+48 574 944 344", "+48 606 905 890", "+48 508 773 370", "+48 531 515 517"],
          email: "biuro@mywash.pl",
          priceRange: "$$",
          address: {
            "@type": "PostalAddress",
            streetAddress: "ul. Sarnia 2",
            addressLocality: "Sobolewo",
            postalCode: "15-509",
            addressRegion: "Podlaskie",
            addressCountry: "PL",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 53.1248559,
            longitude: 23.137594900000067,
          },
        })}
      </script>
    </Helmet>
  )
}

export default SEO
